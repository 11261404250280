import * as i from 'types';
import * as React from 'react';
import Head from 'next/head';

import { useRouter } from 'hooks';
import { isServer } from 'services';
import { getMainNavigation, getFooter } from 'services/contentful';
import contentfulClient from 'services/contentful/contentfulClient';
import PageLayout from 'layouts/PageLayout';
import { PageBody, PageHeader } from 'modules/page';

const Home: i.NextPageComponent<HomeProps> = ({ page }) => {
  const router = useRouter();

  React.useEffect(() => {
    const authenticationCookieValue =
      !isServer &&
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('authenticated='))
        ?.split('=')[1];
    const isAuthenticated = Boolean(authenticationCookieValue);

    if (!isAuthenticated && !isServer) {
      router.push('/login');
    }
  }, []);

  return (
    <>
      <Head>
        <title>Goed Gezien - {page?.fields.title}</title>
        <meta name="description" content={page?.fields.seo?.fields.metaDescription} />
      </Head>
      <PageHeader data={page.fields} />
      <PageBody fields={page.fields} />
    </>
  );
};

type HomeProps = {
  page: i.TypePageGeneral;
};

export const getStaticProps = async () => {
  const page = await contentfulClient.withoutUnresolvableLinks.getEntries<i.TypePageGeneralFields>({
    content_type: 'pageGeneral',
    'fields.seo.sys.contentType.sys.id': 'seo',
    'fields.seo.fields.slug': '/',
    include: 10,
  });
  const navigation = await getMainNavigation();
  const footer = await getFooter();

  return {
    props: {
      page: page.items[0],
      navigation,
      footer,
    },
  };
};

Home.layout = (page, pageProps) => {
  return (
    <PageLayout
      navigation={pageProps.navigation}
      footer={pageProps.footer}
      withoutContainer
      withoutPadding
      withTransparentHeader
    >
      {page}
    </PageLayout>
  );
};

export default Home;
